export class ActivityLeadMaster {
    CUSTOMER_ID: any;
    ID: any;
    CLIENT_ID: any;
    ACTIVITY_TYPE_ID: any;
    ACTIVITY_ID: any;
    ACTION_ID;
    EXECUTIVE_ID;
    CALL_STATUS: string
    CALLING_DATE_TIME
    RESCHEDULE_DATE_TIME: any
    FIELD_1;
    FIELD_2;
    FIELD_3;
    SHEET_ID
    ASSIGNED_ID
    CCE_ID
    REMARK
    FIELD_4;
    FIELD_5;
    RESHEDULED_DATE
    RESHEDULED_TIME
    BT_NUMBER
    BOOKING_DATE: any
    BT_DATE_TIME: any
    BOOKING_TIME: any
    CALLING_TIME:any = new Date()
    REMINDER_DATE:any = new Date()
    REMINDER_TIME
    SELF_REMINDER_DATE
    SELF_REMINDER_TIME
   CURRENT_READING
    LOST_SUB_CATEGOREY
    REASON
    EXPRESS_CARE
    WORK_TYPE
    CALLING_STATUS
    RO_NUMBER
    RO_DATE
IS_PICK_UP_AND_DROP
    PICK_UP_DATE
    PICK_UP_TIME
    SPECIAL_MESSAGE 
    CALL_TYPE: any
    LOCATION: any
    CALLED_BY: any
     IS_VIDEO_CALL
    IS_LIVE_STREAMING
    CALLED_BY_NAME
    CATEGORY_STATUS
    IS_REGISTERED_WITH_MAI
    IS_WARRENTY_AVAILED
    IS_SOT_AVAILED
    IS_SOT_SUPER_AVAILED
    IS_PUC_AVAILED
    IS_INSURANCE_AVAILED
    IS_RSA_AVAILED
    IS_CONFIRMATION_DONE
    SERVICE_ADVISER_ID
    CUSTOMER_NAME
    MOBILE_NUMBER
    NOTIFICATION_ID
    APPOINTMENT_BRANCH : any
    PICK_UP_AND_DROP
    IS_ADDED_BY_HMIL
    HMIL_LOST_STATUS
    ASSIGNED_DATE
}


// "ID": 22815,
//             "BOOKING_DATE": "2024-04-30",
//             "SERVICE_ADVISOR_NAME": "BELEKAR KIRAN APPASO",
//             "CUSTOMER_NAME": "SUMATI BHAUSAHEB KURADE",
//             "MOBILE_NUMBER": "7208318525",
//             "REGISTRATION_NO": "MH09FJ1733",
//             "VIN": "MALAF51CYKM101325",
//             "WORK_TYPE": "PS ",
//             "EXPRESS_CARE": "",
//             "CALLING_DATE": "2024-05-08",
//             "CALLING_STATUS": "-",
//             "REASON": "CALL NR",
//             "MODEL": "NEW SANTRO (1.1 P)",
//             "REMARK": "work done on 04-05-24--R202401397--PMS",
//             "REMINDER_DATE": "2024-03-20",
//             "SPECIAL_MESSAGE": "",
//             "LIVE_STREAMING": "0",
//             "VARIANT": null,
//             "SALE_DATE": "2019-11-16",
//             "PICK_UP_AND_DROP": "",
//             "SRV_MODEL": null,
//             "EMAIL": null,
//             "PURCHASE_DATE": null,
//             "CLIENT_ID": 1,
//             "CATEGORY_STATUS": "MT",
//             "CCE_ID": 273,
//             "BOOKING_TIME": "14:55:44",
//             "VEHICALE_IN_STATUS": "",
//             "VEHICALE_OUT_STATUS": "",
//             "LOST_SUB_CATEGOREY": "L",
//             "BOOKING_TAKEN_ON": "2024-04-29",
//             "SHEET_ID": 273,
//             "CCE_NAME": "DESAI SWARUPA ROHAN",
//             "BRANCH_ID": 7,
//             "EMAIL_ID": "swarupa.era@gmail.com",
//             "EMPLOYEE_CODE": "1447",
//             "ASSIGNED_DATE": "2024-10-30",
//             "CALLING_TIME": "14:34:10",
//             "ASSIGNED_ID": 273,
//             "SELF_REMINDER_DATE": "2024-05-20",
//             "IS_CONFIRMATION_DONE": 0,
//             "ASSIGNED_CATEGOREY": "C",
//             "IS_CALLED_DONE": 0,
//             "SERVICE_ADVISER_ID": 253,
//             "SERVICE_ADVISER_NAME": "BELEKAR KIRAN APPASO",
//             "ADDED_THROUGH_CCE": null,
//             "FORWORDED_ADVISER_ID": 0,
//             "MAI_LOCATION": "null",
//             "BRANCH_NAME": "MAI HYUNDAI ICHALKARANJI",
//             "APPOINTMENT_BRANCH_NAME": "MAI HYUNDAI ICHALKARANJI",
//             "APPOINTMENT_BRANCH": 7,
//             "REMINDER_TIME": "10:00:00",
//             "SELF_REMINDER_TIME": "10:15:00",
//             "VEHICALE_IN_TIME": null,
//             "ASSIGNED_NAME": "DESAI SWARUPA ROHAN",
//             "IMPORTED_CATEGOREY": "L",
//             "APPOINTMENT_BRANCH_ID": 7,
//             "CALL_TYPE": "O",