import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// import { DashboardComponent } from "./pages/CommonModule/Dashboard/dashboard/dashboard.component";
// import { UsersComponent } from "./pages/CommonModule/user/users/users.component";
// import { RolesComponent } from "./pages/CommonModule/role/roles/roles.component";
// import { ApplicationsComponent } from "./pages/CommonModule/application/applications/applications.component";
// import { ModulesComponent } from "./pages/CommonModule/module/modules/modules.component";
// import { SelectpageComponent } from "./pages/CommonModule/selectpage/selectpage/selectpage.component";
import { LoginComponent } from "./login/login.component";

// import { FormsComponent } from "./pages/CommonModule/form/forms/forms.component";
// import { TicketreportComponent } from "./pages/Reports/ticketreport/ticketreport.component";
// import { SummaryreportComponent } from "./pages/Reports/summaryreport/summaryreport.component";
// import { UserwisesummaryComponent } from "./pages/Reports/userwisesummary/userwisesummary.component";
// import { DashboardreportComponent } from "./pages/Report/dashboardreport/dashboardreport.component";

// import { TickRolesComponent } from "./pages/RoleMaster/tick-roles/tick-roles.component";

import { AuthGuard } from './Service/auth.guard';

import { EmploginComponent } from "./emplogin/emplogin.component";


// import { QuotationwisereportsComponent } from './pages/Reports_UI/quotationwisereports/quotationwisereports.component';

// import { InsurancereportComponent } from "./pages/Reports_UI/insurancereport/insurancereport.component";

// import { DetailedReportsComponent } from "./pages/Reports_UI/detailed-reports/detailed-reports.component";

// import { BranchWiseSalesSummryComponent } from "./pages/Reports_UI/branch-wise-sales-summry/branch-wise-sales-summry.component";
// import { ModelWiseSalesSummryComponent } from "./pages/Reports_UI/model-wise-sales-summry/model-wise-sales-summry.component";
// import { TransmissionTypeWiseSalesSummaryComponent } from "./pages/Reports_UI/transmission-type-wise-sales-summary/transmission-type-wise-sales-summary.component";
// import { FuelTypeWiseSalesSummaryComponent } from "./pages/Reports_UI/fuel-type-wise-sales-summary/fuel-type-wise-sales-summary.component";


// import { DistrictWiseSalesSummryComponent } from './pages/Reports_UI/district-wise-sales-summry/district-wise-sales-summry.component'

// import { BranchwiseinsurancesummaryreportComponent } from "./pages/Report/branchwiseinsurancesummaryreport/branchwiseinsurancesummaryreport.component";
// import { AlliedservicesComponent } from "./pages/Report/alliedservices/alliedservices.component";
// import { SalesExeWiseModelWiseQuotationsGeneratedReportComponent } from "./pages/Report/sales-exe-wise-model-wise-quotations-generated-report/sales-exe-wise-model-wise-quotations-generated-report.component";
// import { SalesExecutiveWiseModelWiseGeneratedReportComponent } from "./pages/Report/sales-executive-wise-model-wise-generated-report/sales-executive-wise-model-wise-generated-report.component";

// import { FinanceuireportComponent } from "./pages/Reports_UI/financeuireport/financeuireport.component";

// import { AllReportsComponent } from "./pages/Reports_UI/all-reports/all-reports.component";

// import { ProductModelReportComponent } from "./pages/Report/product-model-report/product-model-report.component";
// import { ProductFuelReportComponent } from "./pages/Report/product-fuel-report/product-fuel-report.component";
// import { ProductTransmissionReportComponent } from "./pages/Report/product-transmission-report/product-transmission-report.component";
// import { ProductVarientWiseReportComponent } from "./pages/Report/product-varient-wise-report/product-varient-wise-report.component";
// import { DailyVehicleInComponent } from "./pages/ActivityMaster/S Reports/daily-vehicle-in/daily-vehicle-in.component";

// import { AlliedSInsuranceSalesReportComponent } from "./pages/Report/allied-sinsurance-sales-report/allied-sinsurance-sales-report.component";
// import { AlliedEWSalesReportComponent } from "./pages/Report/allied-ewsales-report/allied-ewsales-report.component";
// import { AlliedSOTSalesReportComponent } from "./pages/Report/allied-sotsales-report/allied-sotsales-report.component";
// import { AlliedACCESSORIESSalesReportComponent } from "./pages/Report/allied-accessoriessales-report/allied-accessoriessales-report.component";
// import { ServiceDiscountSalesReportComponent } from "./pages/Report/service-discount-sales-report/service-discount-sales-report.component";
// import { CancellationSummaryReportComponent } from "./pages/Report/cancellation-summary-report/cancellation-summary-report.component";
// import { ModificationSummaryreportComponent } from "./pages/Report/modification-summaryreport/modification-summaryreport.component";
// import { ExchangeSalesReportComponent } from "./pages/Report/exchange-sales-report/exchange-sales-report.component";
// import { DetailesCancellationReportComponent } from "./pages/Report/detailes-cancellation-report/detailes-cancellation-report.component";
// import { DetailedModificationReportComponent } from "./pages/Report/detailed-modification-report/detailed-modification-report.component";
// import { CorporateSalesReportComponent } from "./pages/Report/corporate-sales-report/corporate-sales-report.component";
// import { DetailedNewReportComponent } from "./pages/Report/detailed-new-report/detailed-new-report.component";

// import { FinanceincentiveallocationComponent } from "./pages/Report/financeincentiveallocation/financeincentiveallocation.component";
// import { FinanceincentiveallocationsummaryComponent } from "./pages/Report/financeincentiveallocationsummary/financeincentiveallocationsummary.component";
// import { UserwiseFeedBackComplaintReportComponent } from "./pages/Report/userwise-feed-back-complaint-report/userwise-feed-back-complaint-report.component";
// import { ResolverwiseFeedBackComplaintSummaryReportComponent } from "./pages/Report/resolverwise-feed-back-complaint-summary-report/resolverwise-feed-back-complaint-summary-report.component";
// import { UserWiseDetailedReportComponent } from "./pages/Report/user-wise-detailed-report/user-wise-detailed-report.component";
// import { BranchWiseFeedbackReportComponent } from "./pages/Report/branch-wise-feedback-report/branch-wise-feedback-report.component";

// import { TestmoduleComponent } from './pages/LogModule/testmodule/testmodule.component';
// import { TrainerAccessorLoginComponent } from "./trainer-accessor-login/trainer-accessor-login.component";
// import { LeaveBalancereportComponent } from "./setofreports/Reports_UI/Leave reports/leave-balancereport/leave-balancereport.component";
// import { DashboardreportComponent } from "./alliedreports/dashboardreport/dashboardreport.component";
// import { ScheduleMasterComponent } from "./pages/Schedule Reports/schedule-master/schedule-master.component";
// import { ScAndTlIncentiveComponent } from "./pages/Report/sc-and-tl-incentive/sc-and-tl-incentive.component";
// import { ExchangeComponent } from "./pages/Report/Reports-sales/exchange/exchange.component";
// import { CorporateComponent } from "./pages/Report/Reports-sales/corporate/corporate.component";
// import { EWComponent } from "./pages/Report/Reports-sales/ew/ew.component";
// import { FinanceComponent } from "./pages/Report/Reports-sales/finance/finance.component";
// import { InsuranceReportsalesComponent } from "./pages/Report/Reports-sales/insurance-reportsales/insurance-reportsales.component";
// import { SOTComponent } from "./pages/Report/Reports-sales/sot/sot.component";
export const routes: Routes = [
  { path: "Master", loadChildren: () => import("./Master/Master.module").then((m) => m.MasterModule), },
  { path: "leavecalculation", loadChildren: () => import("./Leave Calculation Module/Leave_Calculation.module").then((m) => m.LeaveCalculationMasterModule), },
  { path: "service", loadChildren: () => import("./Servicemodule/Servicemodule.module").then((m) => m.ServicemoduleModule), },
  // { path: "reports", loadChildren: () => import("./NewReportsMain24/reports24-main.module").then((m) => m.Reports24MainModule), },
  { path: "tour", loadChildren: () => import("./TourModule/TourModule.module").then((m) => m.TourModuleModule), },
  { path: "sales", loadChildren: () => import("./salesmodule/sales.module").then((m) => m.SalesModule), },
  { path: "training", loadChildren: () => import("./trainingmodule/TrainingModule.module").then((m) => m.TrainingModuleModule), },
  { path: "view", loadChildren: () => import("./viewmodule/ViewModule.module").then((m) => m.ViewModuleModule), },
  { path: "User", loadChildren: () => import("./usermodule/UserModule.module").then((m) => m.UserModuleModule), },
  { path: 'emm', loadChildren: () => import('./emm-module/emm-module.module').then(m => m.EmmModuleModule) },
  { path: "Support", loadChildren: () => import("./supportmodule/SupportModule.module").then((m) => m.SupportModuleModule), },
  { path: "FoldersAnd", loadChildren: () => import("./foldermodule/FolderfileModule.module").then((m) => m.FolderFileModuleModule), },
  // { path: "hrmodule", loadChildren: () => import("./hrmodule/hrModule.module").then((m) => m.HRModuleModule), },
  { path: "incentiveforms", loadChildren: () => import("./incentiveformodule/IncentiveModule.module").then((m) => m.IncentiveModuleModule), },
  { path: "feedforms", loadChildren: () => import("./feedformodule/FeedModule.module").then((m) => m.FeedModuleModule), },
  { path: "materialissue", loadChildren: () => import("./materialIssuemodule/MaterialModule.module").then((m) => m.MaterialModuleModule), },
  { path: "Document", loadChildren: () => import("./documentmodule/DocModule.module").then((m) => m.DocModuleModule), },
  { path: "hrmodule", loadChildren: () => import("./hr-module/hr-module.module").then((m) => m.HRModuleModule), },
  { path: "alliedservice", loadChildren: () => import("./alliedreports/alliedreports.module").then((m) => m.AlliedreportsModule), },
  { path: "common", loadChildren: () => import("./CommonModule/common-module.module").then((m) => m.CommonModuleModule), },
  { path: "reportset", loadChildren: () => import("./setofreports/setofreports.module").then((m) => m.SetofreportsModule), },
  { path: "newreports", loadChildren: () => import("./NewReports/NewReportModule.module").then((m) => m.NewReportModuleModule), },
  { path: "salesreports", loadChildren: () => import("./reportsofsales/reportsofsales.module").then((m) => m.ReportsofsalesModule), },
  { path: "psf", loadChildren: () => import("./psfmodule/psfmodule.module").then((m) => m.PSFModuleModule), },

  { path: "insurance", loadChildren: () => import("./insurancemodule/insurancemodule.module").then((m) => m.InsuranceModuleModule), },
  { path: "psfreports", loadChildren: () => import("./psfreportss/psfreportmodule.module").then((m) => m.PSFReportModuleModule) },
  { path: "servicereports", loadChildren: () => import("./ServiceReports/ServiceReportsModule.module").then((m) => m.ServiceReportsModuleModule), },
  // { path: "log", loadChildren: () => import("./logmodule/LogsModule.module").then((m) => m.LogsModuleModule), },

  // { path: "Branchwiseinsurancesummaryreport", component: BranchwiseinsurancesummaryreportComponent, canActivate: [AuthGuard] },
  // { path: "Alliedservices", component: AlliedservicesComponent },
  // { path: "salesModelQuotationReport", component: SalesExeWiseModelWiseQuotationsGeneratedReportComponent, canActivate: [AuthGuard] },
  // { path: "SalesExecutiveWiseModelWiseGenerated", component: SalesExecutiveWiseModelWiseGeneratedReportComponent, canActivate: [AuthGuard] },
  { path: "login", redirectTo: "login" },
  { path: "", pathMatch: "full", redirectTo: "/login" },
  { path: "login", component: LoginComponent },
  { path: "orglogin", component: LoginComponent },
  { path: "adminlogin", component: EmploginComponent },
  // { path: "traineraccessorlogin", component: TrainerAccessorLoginComponent },
  // { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: "admindashboard", component: DashboardreportComponent, canActivate: [AuthGuard] },



  // { path: "users", component: UsersComponent, canActivate: [AuthGuard] },
  // { path: "roles", component: RolesComponent, canActivate: [AuthGuard] },
  // { path: "forms", component: FormsComponent, canActivate: [AuthGuard] },

  // { path: "reports/tickets", component: TicketreportComponent, canActivate: [AuthGuard] },
  // { path: "reports/departmentwisesummary", component: SummaryreportComponent, canActivate: [AuthGuard] },
  // { path: "reports/userwisesummary", component: UserwisesummaryComponent, canActivate: [AuthGuard] },
  // { path: "reports/OptionWiseResport", component: OptionreportComponent, canActivate: [AuthGuard] },

  // { path: "tickrole", component: TickRolesComponent, canActivate: [AuthGuard] },

  // { path: "faqresponses", component: FaqresponsereportComponent, canActivate: [AuthGuard] },

  // { path: "Schedule", component: ScheduleMasterComponent, canActivate: [AuthGuard] },

  // { path: "sales_Dashboard", component: SalesDashboardComponent, canActivate: [AuthGuard] },
  // { path: "hr_Dashboard", component: HRDashboardComponent, canActivate: [AuthGuard] },

  // { path: 'DetailedReportsComponent', component: DetailedReportsComponent, canActivate: [AuthGuard] },

  // { path: "payslip", component: PayslipComponent, canActivate: [AuthGuard] },

  // { path: "Salesreports", component: SalesreportComponent, canActivate: [AuthGuard] },
  // { path: "ModelWiseReportList", component: ModelWiseReportComponent, canActivate: [AuthGuard] },

  // { path: "IncentiveReportsList", component: IncentiveReportsComponent, canActivate: [AuthGuard] },

  // { path: "SalesHeadList", component: SalesHeadReportComponent, canActivate: [AuthGuard] },

  // { path: "SalesManagerList", component: SalesManageReportComponent, canActivate: [AuthGuard] },

  // { path: "CustomerWiseList", component: CustomerWiseReportComponent, canActivate: [AuthGuard] },

  // { path: "BankWiseList", component: BankWiseReportComponent, canActivate: [AuthGuard] },

  // { path: "MemoWiseReport", component: MemoWiseReportComponent, canActivate: [AuthGuard] },

  // { path: "GeneralManagerWise", component: GeneralManagerWiseReportComponent, canActivate: [AuthGuard] },

  // { path: "Discountreport", component: DiscountReportComponent, canActivate: [AuthGuard] },

  // { path: "BranchWiseReport", component: BranchWiseComponent, canActivate: [AuthGuard] },

  // { path: 'InhouseInsurance', component: InhouseInsuranceReportComponent, canActivate: [AuthGuard] },

  // { path: 'OuthouseInsurance', component: OuthouseInsuranceReportComponent, canActivate: [AuthGuard] },

  // { path: 'BranchWiseMemoPendancy', component: BranchWiseMemoPendancyReportComponent, canActivate: [AuthGuard] },

  // { path: 'BranchWiseMostDiscountApproval', component: BranchWiseMostDiscountApprovalReportComponent, canActivate: [AuthGuard] },

  // { path: 'BranchWiseDiscountPendancy', component: BranchWiseDiscountPendancyReportComponent, canActivate: [AuthGuard] },

  // { path: "MontlyModelWiseInvoiceGenerated", component: MontlymodelwiseinvoicegeneratedComponent, canActivate: [AuthGuard] },

  // { path: "MontlyBranchWiseModelWiseInvoiceGenerated", component: MontlybranchwisemodelwiseinvoicegeneratedComponent, canActivate: [AuthGuard] },

  // { path: "InhouseFinanceReport", component: InhouseFinanceReportComponent, canActivate: [AuthGuard] },

  // { path: "OuthouseFinanceReport", component: OuthouseFinanceReportComponent, canActivate: [AuthGuard] },

  // { path: "SalesExecutiveWiseQuotationGeneratedTodayReport", component: SalesExecutiveWiseQuotationGeneratedTodayReportComponent, canActivate: [AuthGuard] },

  // { path: "leavebalance", component: LeaveBalancereportComponent, canActivate: [AuthGuard] },

  // // { path: "LeaveTransactionDetails", component: LeaveTransactionDetailesreportComponent, canActivate: [AuthGuard] },

  // // { path: "LeaveTransactionSummaryReportComponent", component: LeaveTransactionSummaryReportComponent, canActivate: [AuthGuard] },

  // { path: "FinanceuireportComponent", component: FinanceuireportComponent, canActivate: [AuthGuard] },

  // { path: "InsurancereportComponent", component: InsurancereportComponent, canActivate: [AuthGuard] },


  // { path: 'DistrictWiseSales', component: DistrictWiseSalesSummryComponent, canActivate: [AuthGuard] },

  // { path: 'allreport', component: AllReportsComponent, canActivate: [AuthGuard] },



  // { path: "canactive", component: TestmoduleComponent, canActivate: [AuthGuard] },


  // 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }