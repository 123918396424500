import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, AsyncPipe, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NzSwitchModule } from 'ng-zorro-antd/switch'; 
import { EmploginComponent } from './emplogin/emplogin.component'; 
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NzInputModule } from 'ng-zorro-antd/input'; 
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { AddNewNotificationDrawerComponent } from './pages/NotificationModule/add-new-notification-drawer/add-new-notification-drawer.component';
import { SendEmailDrawerComponent } from './pages/NotificationModule/send-email-drawer/send-email-drawer.component';
import { ActleadComponent } from './pages/NotificationModule/actlead/actlead.component';
import { PsfledComponent } from './pages/NotificationModule/psfled/psfled.component'; 
import { ChangePasswordDrawerComponent } from './pages/change-password-drawer/change-password-drawer.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent, 
    LoginComponent, 
    EmploginComponent, 
    ViewNotificationDrawerComponent,
    ActleadComponent,
    PsfledComponent,
    AddNewNotificationDrawerComponent,
     SendEmailDrawerComponent,
    ChangePasswordDrawerComponent, 
    ExportDirective, 



  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NzSwitchModule, 
    NgxQRCodeModule,
    AngularFireModule.initializeApp(environment.firebase), 
    NzInputModule, 
  ],

  

  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService, AsyncPipe, DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule { }
