import { Component, OnInit } from '@angular/core';
import { ApiService } from '../Service/api.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-piedashboard',
  templateUrl: './piedashboard.component.html',
  styleUrls: ['./piedashboard.component.css']
})
export class PiedashboardComponent implements OnInit {
  startValueabsent = new Date();
  BRANCH_ABSENT: any = [];
  START_DATE: any;
  loadingRecords:boolean=false
  totalRecords=1
  dataList:any=[]
  filteredDepartments:any=[]
  TRAININGs = 0;
  TOURs = 0;
  SECOND_SHIFTs = 0;
  PUNCHs = 0;
  LEAVE_COUNTs = 0;
  LATEs = 0;
  HALFDAY_COUNTs = 0;
  COMPOFFs = 0;
  ABSENTs = 0;
  SHORT_LEAVEs = 0
  constructor(private api:ApiService,private datePipe:DatePipe) { }

  ngOnInit() {
    // this.getcount()
  }
// getcount(){
//   var START_DATE = ""; 
//   if (this.startValueabsent != null && this.startValueabsent != undefined) {
//     START_DATE = this.datePipe.transform(this.startValueabsent, "yyyy-MM-dd");
//   }
//   var BRANCH_ABSENT = ""
 
//   if (this.BRANCH_ABSENT != undefined && this.BRANCH_ABSENT.length > 0) {
//     BRANCH_ABSENT = this.BRANCH_ABSENT;
//   }
//   this.api
//   .getAttendanceRegister1(
//     0,
//     0,
//     '',
//     '',
//     ''  ,
//     BRANCH_ABSENT,
//     START_DATE
//   )
//   .subscribe(
//     (data) => {
//       if (data["code"] == 200) {
//         this.loadingRecords = false; 
//         this.totalRecords = data["count"];
//         this.dataList = data["data"];
//         this.filteredDepartments = this.dataList.filter(department => department.TOTAL_EMPLOYEE !== 0);

       
//         for (var i = 0; i < this.filteredDepartments.length; i++) {
//           if (this.filteredDepartments[i]["TRAINING"] != undefined) {
//             this.TRAININGs =
//               this.TRAININGs + this.filteredDepartments[i]["TRAINING"];
//           }
//           if (this.filteredDepartments[i]["TOUR"] != undefined) {
//             this.TOURs = this.TOURs + this.filteredDepartments[i]["TOUR"];
//           }
//           if (this.filteredDepartments[i]["SECOND_SHIFT"] != undefined) {
//             this.SECOND_SHIFTs =
//               this.SECOND_SHIFTs + this.filteredDepartments[i]["SECOND_SHIFT"];
//           }
//           if (this.filteredDepartments[i]["PUNCH"] != undefined) {
//             this.PUNCHs = this.PUNCHs + this.filteredDepartments[i]["PUNCH"];
//           }
//           if (this.filteredDepartments[i]["LEAVE_COUNT"] != undefined) {
//             this.LEAVE_COUNTs =
//               this.LEAVE_COUNTs + this.filteredDepartments[i]["LEAVE_COUNT"];
//           }
//           if (this.filteredDepartments[i]["LATE"] != undefined) {
//             this.LATEs = this.LATEs + this.filteredDepartments[i]["LATE"];
//           }
//           if (this.filteredDepartments[i]["HALFDAY_COUNT"] != undefined) {
//             this.HALFDAY_COUNTs =
//               this.HALFDAY_COUNTs + this.filteredDepartments[i]["HALFDAY_COUNT"];
//           }
//           if (this.filteredDepartments[i]["COMPOFF"] != undefined) {
//             this.COMPOFFs = this.COMPOFFs + this.filteredDepartments[i]["COMPOFF"];
//           }
//           if (this.filteredDepartments[i]["ABSENT"] != undefined) {
//             this.ABSENTs = this.ABSENTs + this.filteredDepartments[i]["ABSENT"];
//           }
//           if (this.filteredDepartments[i]["SHORT_LEAVE"] != undefined) {
//             this.SHORT_LEAVEs =
//               this.SHORT_LEAVEs + this.filteredDepartments[i]["SHORT_LEAVE"];
//           }
//         }
        
//       }
//     },
//     (err) => {
//       console.log(err);
//     }
//   );
// }

 
SELECT_BRANCH:boolean=false
branch:any=[]
clearFilter() {
  this.BRANCH_ABSENT = [];
  this.startValueabsent = null; 
  this.SELECT_BRANCH = false;  
//  this.getcount()
}

onSelectAllBranch(event: any) {
  this.SELECT_BRANCH = event;
  console.log(event, "Event");
  let ids = [];
  if (this.SELECT_BRANCH == true) {
    for (var i = 0; i < this.branch.length; i++) {
      ids.push(this.branch[i]["ID"]);
    }
  } else {
    ids = [];
  }
  this.BRANCH_ABSENT = ids;
}
onSelectOff1(event) {
  var a = this.branch.length;
  var b = this.branch.length - event.length;
  if ((a! = b)) {
    this.SELECT_BRANCH = false;
  } else {
    this.SELECT_BRANCH = true;
  }
  this.BRANCH_ABSENT = event;
  if (this.BRANCH_ABSENT.length == 0) {
    this.SELECT_BRANCH = false;
  }
}
}
